import {
  Button,
  Card,
  CheckCircleSVG,
  CopySVG,
  CrossCircleSVG,
  EnsSVG,
  Input,
  RightChevronSVG,
  Toast,
  Typography,
} from "@ensdomains/thorin";
import "@rainbow-me/rainbowkit/styles.css";
import axios from "axios";
import { ReactNode, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Hash, UserRejectedRequestError } from "viem";
import { namehash } from "viem/ens";
import { useAccount, useSignMessage } from "wagmi";
import { useGetApi } from "../../api/use-api";
import ensRegistryAbi from "../../web3/abi/ens-registry.json";
import nameWrapperAbi from "../../web3/abi/name-wrapper.json";
import { useGetAddresses } from "../../web3/use-get-addresses";
import { useWeb3Clients } from "../../web3/use-web3-clients";

export default function Registration() {
  const Registration = () => {
    const { address: wallet, isConnected } = useAccount();
    const [params] = useSearchParams();
    const [disabled, setDisabled] = useState(true);
    const [hidden, setHidden] = useState(true);
    const [message, setMessage] = useState<ReactNode>();
    const [regMessage, setRegMessage] = useState("");
    const [regIcon, setRegIcon] = useState<ReactNode>(null);
    const [buttonLabel, setButtonLabel] = useState("");
    const [ensName, setEnsName] = useState("");
    const [resolverSet, setResolverSet] = useState(false);
    const [apiKey, setApiKey] = useState("");
    const [apiKeyCopied, setApiKeyCopied] = useState(false);
    const [codeCopied, setCodeCopied] = useState(false);
    const [id, setRegId] = useState<string | null>(null);
    const [registrationType, setRegistrationType] = useState<
      "signup" | "quicknode"
    >();
    const [isError, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [signed, setSigned] = useState(false);
    const [registered, setRegistered] = useState(false);
    const {
      data: signature,
      isError: errorSigning,
      isSuccess: signingSucceeded,
      signMessageAsync,
    } = useSignMessage();
    const { ensRegistry, nameWrapper, offchainResolver } = useGetAddresses();
    const { publicClient, walletClient } = useWeb3Clients();
    const { apiDomain } = useGetApi();

    // event handlers
    useEffect(() => {
      const regId = params.get("reg_id");
      if (regId) {
        setRegId(regId);
        setRegistrationType("signup");
      } else {
        const quicknodeId = params.get("quicknode_id");
        if (quicknodeId) {
          setRegId(quicknodeId);
          setRegistrationType("quicknode");
        }
      }
    }, [params]);

    useEffect(() => {
      if (signingSucceeded === true) {
        setSigned(true);
        register();
      }
    }, [signingSucceeded]);

    useEffect(() => {
      if (errorSigning) setError(true);
    }, [errorSigning]);

    useEffect(() => {
      if (signed || registered) {
        setError(false);
        setErrorMsg("");
      }
      if (registered) {
        setRegMessage("Signup Successfully Completed");
        setRegIcon((<CheckCircleSVG className="thorin-blue" />) as ReactNode);
      }
    }, [signed, registered]);

    const onEnsNameChange = async (e: any) => {
      setError(false);

      const name = e.target.value;
      setEnsName(name);

      const isFullName = /.{3,}\.eth$/.test(name);
      setDisabled(!isFullName);

      if (isFullName) {
        try {
          const hasPermission = await getPermission(name);

          if (!hasPermission) {
            setMessage(
              <Typography>
                You are not permitted to register
                <b> {name} </b>
                with <b> {wallet} </b>
              </Typography>,
            );

            setHidden(true);
            return;
          }

          setHidden(false);

          const resolver = await getNameResolver(name);
          const resolverSet =
            resolver?.toLocaleLowerCase() ===
            offchainResolver.toLocaleLowerCase();

          setResolverSet(resolverSet);

          if (resolverSet) {
            setMessage(null);
            setButtonLabel("Continue with signup");
          } else {
            setMessage(`Proceed to set the Offchain Resolver for ${name}`);
            setButtonLabel("Set Offchain Resolver");
          }
        } catch (error) {
          setError(true);
          if (error instanceof Error) {
            setErrorMsg(error.message);
          }
        }
      }
    };

    const onRegistration = async () => {
      if (!resolverSet) {
        try {
          const isWrapped = (await isNameWrapped(ensName)) as boolean;
          const txHash = (await setOffchainResolver(
            ensName,
            isWrapped,
          )) as Hash;
          setDisabled(true);
          await publicClient?.waitForTransactionReceipt({ hash: txHash });
          setDisabled(false);
          setResolverSet(true);

          setMessage("");
          setButtonLabel("Continue with signup");
        } catch (error) {
          setError(true);
          if (error instanceof Error) {
            setErrorMsg(error.message);
          }
        }

        return;
      }

      setDisabled(true);
      await signMessage();
      setDisabled(false);
      setHidden(true);
    };

    async function getNameResolver(name: string) {
      return (await publicClient?.readContract({
        functionName: "resolver",
        args: [namehash(name)],
        abi: ensRegistryAbi,
        address: ensRegistry,
      })) as string;
    }

    async function isNameWrapped(name: string) {
      return await publicClient?.readContract({
        functionName: "isWrapped",
        args: [namehash(name)],
        abi: nameWrapperAbi,
        address: nameWrapper,
      });
    }

    // functions
    async function getPermission(name: string) {
      const _canModifyName = await publicClient?.readContract({
        address: nameWrapper,
        abi: nameWrapperAbi,
        functionName: "canModifyName",
        args: [namehash(name), wallet],
      });

      if (_canModifyName) return true;

      const _owner = (await publicClient?.readContract({
        address: ensRegistry,
        abi: ensRegistryAbi,
        functionName: "owner",
        args: [namehash(name)],
      })) as string;

      if (_owner.toLocaleLowerCase() === wallet?.toLocaleLowerCase()) {
        return true;
      }

      return await publicClient?.readContract({
        address: ensRegistry,
        abi: ensRegistryAbi,
        functionName: "isApprovedForAll",
        args: [_owner, wallet],
      });
    }

    const setOffchainResolver = async (ensName: string, isWrapped: boolean) => {
      const contractAbi = isWrapped ? nameWrapperAbi : ensRegistryAbi;
      const contractAddr = isWrapped ? nameWrapper : ensRegistry;
      const { request } = (await publicClient?.simulateContract({
        abi: contractAbi,
        address: contractAddr,
        functionName: "setResolver",
        account: wallet,
        args: [namehash(ensName), offchainResolver],
      })) as any;
      return (await walletClient?.writeContract(request)) as Hash;
    };

    async function signMessage() {
      try {
        await signMessageAsync({
          message: `reg_id=${id},ens_name=${ensName}`,
        });
      } catch (error) {
        console.log(error);
        setError(true);
        if (error instanceof UserRejectedRequestError) {
          setErrorMsg("Signup declined");
        } else if (error instanceof Error) {
          setErrorMsg(error.message);
        }
        setDisabled(false);
      }
    }

    function register() {
      axios
        .post(
          // `https://offchain-mainnet.namespace.tech/v1/registration/${registrationType}`,
          `${apiDomain}/v1/registration/${registrationType}`,
          {
            signature,
            wallet,
            id,
            ensName,
          },
        )
        .then((resp) => handleRegResponse(resp.data.apiKey))
        .catch((err) => {
          console.log(err.response.data.error[0].message);
          setErrorMsg(err.response.data.error[0].message);
          setError(true);
        });
    }

    function handleRegResponse(apiKey: string) {
      setApiKey(apiKey);
      setRegistered(true);
    }

    function copyApiKey() {
      navigator.clipboard.writeText(apiKey);
      setApiKeyCopied(true);
      setTimeout(() => setApiKeyCopied(false), 2000);
    }

    function copyCode() {
      navigator.clipboard.writeText(code);
      setCodeCopied(true);
      setTimeout(() => setCodeCopied(false), 2000);
    }

    const code = `
      curl -L \\ 
      -X POST \\ 
      -H 'Content-Type: application/json' \\
      -H 'Authorization: Bearer ${apiKey}' \\
      '${apiDomain}/v1/subname/mint' \\ 
      -d '{"label": "_choose_your_label_", "domain": "${ensName}", "address": "${wallet}"}'
    `;

    return (
      <div className="grid">
        {/* connect button */}
        <div hidden={isConnected}>
          <div className="mt-40 flex w-full justify-center">
            <Typography>
              Connect your wallet to start the signup process.
            </Typography>
          </div>
        </div>
        <div hidden={!isConnected}>
          <div className="m-auto mt-5 grid w-full justify-center md:mt-20 lg:w-1/2 xl:w-1/3">
            {/* registration field */}
            <div hidden={registered} className="m-auto max-w-lg">
              <Typography
                fontVariant="headingThree"
                color="blue"
                className="mb-10"
              >
                What ENS name are you signing up with?{" "}
              </Typography>
              <Input
                prefix={<EnsSVG />}
                label="Provide Full ENS Name"
                value={ensName}
                onChange={onEnsNameChange}
              />
            </div>

            {/* registration input message */}
            {message && <div className="m-5 flex text-center">{message}</div>}

            {/* registration button */}
            <div hidden={hidden}>
              <div className="m-5 grid justify-center">
                <Button disabled={disabled} onClick={onRegistration}>
                  {buttonLabel}
                </Button>
              </div>
            </div>

            {/* proccessed registration message */}
            {regIcon && regMessage && (
              <div className="m-5 flex items-center justify-center">
                <div className="mr-1">
                  <Typography>{regMessage}</Typography>
                </div>
                <div className="ml-1">
                  <Typography>{regIcon}</Typography>
                </div>
              </div>
            )}

            {/* error message */}
            <Toast
              open={isError}
              title="Error"
              description="Signup failed due to the error below."
              variant="desktop"
              onClose={() => setError(false)}
              msToShow={3600000}
            >
              <div className="mt-1 flex items-center bg-slate-100 p-1">
                <CrossCircleSVG className="thorin-blue mb-auto mr-1" />
                <Typography>
                  <pre className="w-96 overflow-scroll whitespace-pre-wrap leading-none">
                    {errorMsg && errorMsg.length > 0
                      ? errorMsg
                      : "Transaction error"}
                  </pre>
                </Typography>
              </div>
            </Toast>
          </div>
        </div>

        {/* api key */}
        {apiKey?.length > 0 && isConnected && (
          <div className="m-auto grid w-1/2 items-center">
            <Typography className="flex">
              <RightChevronSVG className="thorin-blue" />{" "}
              <span className="leading-none">
                Make a note of your API key which will be needed to access the
                API.
              </span>
            </Typography>

            <div className="mt-2 flex items-center">
              <Input
                hideLabel
                label=""
                value={apiKey}
                className="text-center font-mono"
                suffix={
                  apiKeyCopied ? (
                    <CheckCircleSVG className="thorin-blue fade-in ml-1 cursor-pointer text-2xl" />
                  ) : (
                    <CopySVG
                      onClick={copyApiKey}
                      className="thorin-blue ml-1 cursor-pointer text-2xl"
                    />
                  )
                }
              />
            </div>

            <div className="mt-5 overflow-auto">
              <Typography className="flex">
                <RightChevronSVG className="thorin-blue" />{" "}
                <span className="leading-none">
                  To get started use the API key as shown below to start minting
                  subnames.
                </span>
              </Typography>

              <Card className="mt-2 w-full">
                <pre className="bg-slate-50 p-3">
                  {codeCopied ? (
                    <CheckCircleSVG className="thorin-blue fade-in ml-auto cursor-pointer text-2xl" />
                  ) : (
                    <CopySVG
                      onClick={copyCode}
                      className="thorin-blue ml-auto cursor-pointer text-2xl"
                    />
                  )}
                  <code className="whitespace-pre-wrap">{code}</code>
                </pre>
              </Card>
            </div>

            <div className="mt-5">
              <Typography className="flex">
                <RightChevronSVG className="thorin-blue" />{" "}
                <span className="leading-none">
                  For more details on other endpoints and how to use the API
                  please refer to the{" "}
                  <a
                    href="https://docs.namespace.tech/dev-docs/offchain-subnames-api"
                    className="thorin-blue underline"
                    target="_blank"
                    rel="noreferrer"
                  >
                    API docs.
                  </a>
                </span>
              </Typography>
            </div>
          </div>
        )}
      </div>
    );
  };

  return <Registration />;
}
