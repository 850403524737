import { Button, Card, Typography } from "@ensdomains/thorin";
import { ConnectWallet } from "./wallet/wallet-connect";
import { useAccount } from "wagmi";
import { useChainModal } from "@rainbow-me/rainbowkit";

export default function Header() {
  const { isConnected } = useAccount();

  return (
    <div className="flex h-28 w-full justify-end bg-[url('assets/ninja-logo.png')] bg-contain bg-left bg-no-repeat">
      {isConnected && (
        <div className="m-5">
          <ChainToggle />
        </div>
      )}
      <div className="m-5">
        <ConnectWallet />
      </div>
    </div>
  );
}

function ChainToggle() {
  const { openChainModal } = useChainModal();

  return <Button onClick={openChainModal}>Switch Chains</Button>;
}
