import {
  Button,
  Card,
  CrossCircleSVG,
  Spinner,
  Toast,
  Typography,
} from "@ensdomains/thorin";
import { PaymentElement, useElements } from "@stripe/react-stripe-js";
import { PaymentIntentResult, Stripe } from "@stripe/stripe-js";
import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAccount } from "wagmi";
import { useGetApi } from "../../api/use-api";
import { RegProps } from "../registration/registration-tiers";

export default function StripePayment({
  stripe,
  regProps,
  clientSecret,
}: {
  stripe: Stripe | null;
  regProps: RegProps;
  clientSecret: string;
}) {
  const elements = useElements();
  const [isError, setError] = useState(false);
  const [restart, setRestart] = useState(false);
  const [processingPayment, setProcessingPayment] = useState<boolean>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const { address } = useAccount();
  const navigate = useNavigate();
  const { apiDomain } = useGetApi();

  async function confirmCardPayment() {
    if (elements) {
      setProcessingPayment(true);
      await elements.submit();
      stripe
        ?.confirmPayment({
          elements,
          clientSecret,
          redirect: "if_required",
        })
        .then(handleConfirmation)
        .catch(handleConfirmationFailed);
    }
  }

  function handleConfirmation(response: PaymentIntentResult) {
    if (response.error?.message) {
      handleConfirmationFailed(response);
      return;
    }

    let intervalId = setInterval(async () => {
      const confirmed = await axios.get(
        `${apiDomain}/v1/registration/account-confirmation/${address}`,
      );

      if (confirmed.data.registered) {
        clearInterval(intervalId);
        setProcessingPayment(false);
        navigate(`/registration/signup?reg_id=${confirmed.data.paymentId}`);
      }
    }, 2000);
  }

  function handleConfirmationFailed(response: PaymentIntentResult) {
    setProcessingPayment(false);
    setError(true);
    setErrorMessage(response.error?.message);
    setRestart(true);
  }

  return (
    <div className="m-auto h-full">
      {address && regProps.selectedTier && (
        <Card className="h-full justify-stretch">
          <div>
            <Typography weight="light">
              For the selected tier{" "}
              <span className="font-bold">
                {regProps.selectedTier.description}
              </span>
              , please provide card details for the payment amount of{" "}
              <span className="font-bold">
                ${(regProps.selectedTier.price / 100).toFixed(2)}
              </span>
            </Typography>
          </div>

          <div>
            <Card.Divider />
          </div>

          <div className="grid h-full content-center">
            <form id="payment-form">
              <div className="mb-4 ml-auto mr-auto mt-4 w-4/5">
                <PaymentElement
                  id="card-element"
                  options={{ layout: "tabs" }}
                />
              </div>
              <div className="m-auto mt-8 w-4/5">
                <Button
                  disabled={processingPayment}
                  onClick={confirmCardPayment}
                >
                  Pay ${(regProps.selectedTier.price / 100).toFixed(2)}
                </Button>
                {processingPayment && (
                  <div className="flex items-center justify-center">
                    <Typography className="mr-2 mt-4">
                      Processing payment
                    </Typography>
                    <Spinner className=" mt-4" />
                  </div>
                )}
              </div>
            </form>
          </div>
        </Card>
      )}

      <Toast
        open={isError}
        title="Error"
        description="Payment failed due to the error below."
        variant="desktop"
        onClose={() => setError(false)}
        msToShow={3600000}
      >
        <div className="mb-1 mt-1 flex items-center bg-slate-100 p-1">
          <CrossCircleSVG className="thorin-blue mb-auto mr-1" />
          <Typography>
            <pre className="w-96 overflow-y-scroll whitespace-pre-wrap leading-none">
              {errorMessage && errorMessage.length > 0
                ? errorMessage
                : "Transaction error"}
            </pre>
          </Typography>
        </div>
        {restart && (
          <div>
            <Button onClick={() => window.location.reload()}>Retry</Button>
          </div>
        )}
      </Toast>
    </div>
  );
}
