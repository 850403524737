import { useAccount } from "wagmi";

export function useGetApi() {
  const { chain } = useAccount();

  const apiDomain = chain?.testnet
    ? process.env.REACT_APP_STAGING_API_DOMAIN
    : process.env.REACT_APP_PROD_API_DOMAIN;

  return { apiDomain, prodDomain: process.env.REACT_APP_PROD_API_DOMAIN };
}
