import { ThorinGlobalStyles, lightTheme } from "@ensdomains/thorin";
import { RainbowKitProvider } from "@rainbow-me/rainbowkit";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { WagmiProvider } from "wagmi";
import Header from "./components/header";
import Registration from "./components/signup/registration";
import WalletRegistration from "./components/registration/wallet-registration";
import { walletConfig } from "./components/wallet/wallet-config";

const queryClient = new QueryClient();

export default function App() {
  return (
    <WagmiProvider config={walletConfig}>
      <QueryClientProvider client={queryClient}>
        <RainbowKitProvider>
          <ThemeProvider theme={lightTheme}>
            <ThorinGlobalStyles />
            <Header />
            <BrowserRouter>
              <Routes>
                <Route path="/registration" element={<WalletRegistration />} />
                <Route
                  path="/registration/quicknode"
                  element={<Registration />}
                />
                <Route path="/registration/signup" element={<Registration />} />
              </Routes>
            </BrowserRouter>
          </ThemeProvider>
        </RainbowKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
  );
}
