import { Button } from "@ensdomains/thorin";
import { useDisconnect } from "wagmi";

export function Account() {
  const { disconnect } = useDisconnect();

  return (
    <div>
      <Button onClick={() => disconnect()}>Disconnect</Button>
    </div>
  );
}
