import {
  Button,
  Card,
  CrossCircleSVG,
  Heading,
  Toast,
  Typography,
} from "@ensdomains/thorin";
import axios from "axios";
import { useState } from "react";
import { SiweMessage } from "siwe";
import { useAccount, useSignMessage } from "wagmi";
import { useGetApi } from "../../api/use-api";
import StripeElements from "../stripe/stripe-elements";
import RegistrationTiers, { RegistrationTier } from "./registration-tiers";

export default function WalletRegistration() {
  const { address, chain } = useAccount();
  const { signMessageAsync } = useSignMessage();

  const [nonce, setNonce] = useState<string>();
  const [accountCreated, setAccountCreated] = useState(false);
  const [selectedTier, setSelectedTier] = useState<RegistrationTier>();
  const [isError, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const { apiDomain } = useGetApi();

  function updateTier(tier: RegistrationTier) {
    setSelectedTier(tier);
  }

  const signIn = async () => {
    try {
      const chainId = chain?.id;
      if (!address || !chainId) return;

      const nonce = await getNonce();

      const siwe = new SiweMessage({
        domain: window.location.host,
        address,
        statement: "Register for offchain API with Ethereum wallet.",
        uri: window.location.origin,
        version: "1",
        chainId,
        nonce,
      });
      const siweSignature = await signMessageAsync({
        message: siwe.prepareMessage(),
      });

      axios
        .post(`${apiDomain}/v1/registration/create-account`, {
          siwe,
          siweSignature,
        })
        .then((resp) => handleAccountResponse(resp.data));
    } catch (error) {
      setErrorMessage("Please try again.");
    }
  };

  async function getNonce() {
    if (!nonce) {
      const nonce = await axios.get(
        `${apiDomain}/v1/registration/nonce/${address}`,
      );
      setNonce(nonce.data);

      return nonce.data;
    }

    return nonce;
  }

  function handleAccountResponse(nonce: string) {
    setAccountCreated(true);
    setNonce(nonce);
  }

  return (
    <div>
      {!accountCreated && (
        <div className="m-auto w-1/3">
          <Card>
            <div className="m-auto flex flex-col items-center">
              <div>
                <Heading color="accent" className="text-center">
                  Namespace Offchain API Signup
                </Heading>
              </div>
              <div className="m-auto pt-4">
                <Button width="45" onClick={signIn}>
                  Start Registration
                </Button>
              </div>
            </div>
            <div>
              <Typography fontVariant="large" className=" text-center">
                Use your wallet to sign in and start the registration
              </Typography>
            </div>
          </Card>
        </div>
      )}

      {accountCreated && (
        <div className="mb-5 flex justify-center">
          <Heading level="2">Select a payment plan</Heading>
        </div>
      )}
      <div className="m-auto flex w-fit justify-center">
        <div className="w-fit">
          {accountCreated && <RegistrationTiers regProps={{ updateTier }} />}
        </div>
        <div className="max-w-96">
          {selectedTier && (
            <StripeElements regProps={{ updateTier, selectedTier, nonce }} />
          )}
        </div>
      </div>

      <Toast
        open={isError}
        title="Error"
        description="Registration error."
        variant="desktop"
        onClose={() => setError(false)}
        msToShow={3600000}
      >
        <div className="mb-1 mt-1 flex items-center bg-slate-100 p-1">
          <CrossCircleSVG className="thorin-blue mb-auto mr-1" />
          <Typography>
            <pre className="w-96 overflow-y-scroll whitespace-pre-wrap leading-none">
              {errorMessage && errorMessage.length > 0
                ? errorMessage
                : "Transaction error"}
            </pre>
          </Typography>
        </div>
        <div>
          <Button onClick={() => window.location.reload()}>Retry</Button>
        </div>
      </Toast>
    </div>
  );
}
