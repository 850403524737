import { Elements } from "@stripe/react-stripe-js";
import { Stripe, loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import { useEffect, useState } from "react";
import { useAccount } from "wagmi";
import { useGetApi } from "../../api/use-api";
import { RegProps } from "../registration/registration-tiers";
import StripePayment from "./stripe-payment";

export default function StripeElements({ regProps }: { regProps: RegProps }) {
  const { address } = useAccount();
  const [key, setKey] = useState<string>();
  const [stripe, setStripe] = useState<Stripe | null>(null);
  const [clientSecret, setClientSecret] = useState("");
  const { apiDomain } = useGetApi();

  useEffect(() => {
    axios
      .get(`${apiDomain}/v1/stripe/public-key`)
      .then((resp) => setKey(resp.data));
  }, [apiDomain]);

  useEffect(() => {
    if (key) {
      loadStripe(key).then((resp) => setStripe(resp));
    }
  }, [key]);

  useEffect(() => {
    createPaymentIntent();
  }, []);

  function createPaymentIntent() {
    axios
      .post(`${apiDomain}/v1/stripe/create-payment-intent`, {
        currency: "usd",
        paymentMethodType: "card",
        tier: regProps.selectedTier,
        amount: regProps.selectedTier?.price,
        wallet: address,
        nonce: regProps.nonce,
      })
      .then((resp) => {
        regProps.nonce = resp.data.nonce;
        const clientSecret = resp.data.intent.client_secret;
        setClientSecret(clientSecret);
        retrievePaymentIntent(clientSecret);
      });
  }

  function retrievePaymentIntent(clientSecret: string) {
    stripe?.retrievePaymentIntent(clientSecret).then(console.log);
  }

  return (
    <>
      {clientSecret && (
        <Elements stripe={stripe} options={{ clientSecret }}>
          <StripePayment
            stripe={stripe}
            regProps={regProps}
            clientSecret={clientSecret}
          />
        </Elements>
      )}
    </>
  );
}
