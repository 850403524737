import { createConfig, http } from "wagmi";
import { mainnet, sepolia } from "wagmi/chains";
import { injected, safe, walletConnect } from "wagmi/connectors";

const projectId = "e811e24d56cec721b6d7e53a5644b9c9";

export const walletConfig = createConfig({
  chains: [mainnet, sepolia],
  connectors: [injected(), walletConnect({ projectId })],
  transports: {
    [mainnet.id]: http(),
    [sepolia.id]: http(),
  },
});
