import { Address } from "viem";
import { useAccount } from "wagmi";

interface ContractAddresses {
  ensRegistry: Address;
  nameWrapper: Address;
  offchainResolver: Address;
}

const addresses: Record<string, ContractAddresses> = {
  "1": {
    ensRegistry: "0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e",
    nameWrapper: "0xD4416b13d2b3a9aBae7AcD5D6C2BbDBE25686401",
    offchainResolver: "0xF12d6Aa997F7c9Fc2E8968A19623211e8C8C1bF4",
  },
  "11155111": {
    ensRegistry: "0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e",
    nameWrapper: "0x0635513f179D50A207757E05759CbD106d7dFcE8",
    offchainResolver: "0x48243237e97257bCC97e53E53149Cbd49DD218EB",
  },
};

export const useGetAddresses = () => {
  const { chain } = useAccount();

  const chainId = chain?.id || 1;
  const chainIdStr = chainId.toString() as string;

  return addresses[chainIdStr];
};
