import { Card, Tag, Typography } from "@ensdomains/thorin";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAccount } from "wagmi";
import { useGetApi } from "../../api/use-api";

export interface RegistrationTier {
  name: string;
  description: string;
  price: number;
  subnameCountRange?: [number, number];
}

export interface RegProps {
  updateTier: (tier: RegistrationTier) => void;
  selectedTier?: RegistrationTier;
  nonce?: string;
}

export default function RegistrationTiers({
  regProps,
}: {
  regProps: RegProps;
}) {
  const { address } = useAccount();
  const navigate = useNavigate();
  const [signedUp, setSignedUp] = useState(false);
  const [signupId, setSignupId] = useState("");
  const [tiers, setTiers] = useState<RegistrationTier[]>();
  const [selectedTier, setSelectedTier] = useState<RegistrationTier>();
  const { apiDomain, prodDomain } = useGetApi();

  useEffect(() => {
    axios
      .get(`${apiDomain}/v1/registration/tiers`)
      .then((resp) => setTiers(resp.data));
  }, [apiDomain]);

  useEffect(() => {
    if (signedUp) {
      navigate(`/registration/signup?reg_id=${signupId}`);
    }
  }, [signedUp, signupId]);

  useEffect(() => {
    axios
      .get(`${prodDomain}/v1/registration/account-confirmation/${address}`)
      .then(setRegistrationStatus);
  }, [address, prodDomain]);

  function setRegistrationStatus(resp: any) {
    setSignedUp(resp.data?.registered);
    setSignupId(resp.data?.paymentId);
  }

  function setTier(tier: RegistrationTier) {
    setSelectedTier(tier);
    regProps.updateTier(tier);
  }

  return (
    <Card>
      <div className="m-auto">
        {tiers?.map((tier) => (
          <Card
            onClick={() => setTier(tier)}
            className="m-5 cursor-pointer"
            style={{
              borderWidth: selectedTier?.name === tier.name ? "10px" : "1px",
            }}
          >
            <div className="flex">
              <div>
                <Typography
                  fontVariant="extraLargeBold"
                  color="textSecondary"
                  className="mb-3 mr-4 mt-3"
                >
                  {tier.description}
                </Typography>
              </div>
              <div className="content-center">
                <Tag size="medium" colorStyle="tealSecondary">
                  ${(tier.price / 100).toFixed(2)}
                </Tag>
              </div>
            </div>
            <Card.Divider />
            <div>
              <Typography weight="light">
                Mint up to {tier.subnameCountRange?.at(1)} subnames for $
                {(tier.price / 100).toFixed(2)}
              </Typography>
            </div>
          </Card>
        ))}
      </div>
    </Card>
  );
}
